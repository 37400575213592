/* Estilos para Chrome/Safari/Edge */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
}

/* Estilos para Firefox */
input:autofill {
    background: #18223e !important;
    color: white !important;
}