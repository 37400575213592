* {
    /* Scrollbar vertical */
    &::-webkit-scrollbar {
      width: 9px;
      cursor: pointer;
    }
  
    /* Scrollbar horizontal */
    &::-webkit-scrollbar:horizontal {
      height: 9px;
      cursor: pointer;
    }
  
    /* Eliminar botones */
    &::-webkit-scrollbar-button {
      display: none;
      width: 0;
      height: 0;
    }
  
    /* Estilo para el corner */
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  
    /* Track (fondo del scrollbar) */
    &::-webkit-scrollbar-track {
      background: #030619;
      cursor: pointer;
    }
  
    /* Thumb (barra deslizadora) con transición */
    &::-webkit-scrollbar-thumb {
      background-color: #030619;
      border-radius: 9px;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;
    }
  
    /* Solo cambiamos el color en hover, manteniendo la transición original */
    &:hover::-webkit-scrollbar-thumb {
      background-color: #3D85FA;
    }
  }