// Menú

// Opción 1: Usando la clase específica del panel
.mat-mdc-menu-panel {
    background: #030619 !important;
    border-radius: 12px !important;
    border-color: #2F4B83;
    border: 1px solid #2F4B83 !important;
    padding: 0 !important;
    margin: 0 !important;

    .mat-mdc-menu-content {
        padding: 0 !important;
        margin: 0 !important;
    }
}