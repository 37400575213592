// Search Input 
.mat-mdc-form-field.custom-search {
    --mdc-filled-text-field-container-color: #0D1126;
    --mdc-filled-text-field-focus-active-indicator-color: #1F50A0;
    --mdc-filled-text-field-active-indicator-color: #1F50A0;
    --mdc-filled-text-field-hover-active-indicator-color: #1F50A0;
    --mdc-filled-text-field-focus-label-text-color: white;
    --mdc-filled-text-field-label-text-color: rgb(156 163 175);
    --mdc-filled-text-field-input-text-color: white;
    --mat-form-field-container-shape: 47px;

    .mat-mdc-text-field-wrapper {
        border: 1px solid #1F50A0;
        border-radius: 47px;
    }

    .mat-mdc-form-field-focus-overlay {
        opacity: 0 !important;
    }

    .mdc-line-ripple {
        display: none !important;
    }

    // Estilos responsivos
    @media (max-width: 639px) {

        // xs
        .mat-mdc-form-field-infix {
            padding: 8px !important;
            font-size: 14px !important;
            min-height: 40px !important;
        }

        .mat-icon {
            font-size: 18px !important;
        }
    }

    @media (min-width: 640px) {

        // sm
        .mat-mdc-form-field-infix {
            padding: 10px !important;
            font-size: 14px !important;
            min-height: 40px !important;
        }

        .mat-icon {
            font-size: 20px !important;
        }
    }

    @media (min-width: 768px) {

        // md
        .mat-mdc-form-field-infix {
            padding: 12px !important;
            font-size: 15px !important;
            min-height: 40px !important;
        }

        .mat-icon {
            font-size: 22px !important;
        }
    }

    @media (min-width: 1024px) {

        // lg
        .mat-mdc-form-field-infix {
            padding: 6px !important;
            font-size: 14px !important;
            min-height: 30px !important;
        }

        .mat-icon {
            font-size: 24px !important;
        }
    }

    @media (min-width: 1280px) {

        // xl
        .mat-mdc-form-field-infix {
            padding: 8px 2px !important;
            font-size: 14px !important;
            min-height: 40px !important;
        }

        .mat-icon {
            font-size: 24px !important;
        }
    }

    @media (min-width: 1536px) {

        // 2xl
        .mat-mdc-form-field-infix {
            padding: 9px !important;
            font-size: 14px !important;
            min-height: 40px !important;
        }

        .mat-icon {
            font-size: 26px !important;
        }
    }
}