@import 'assets/styles/tailwind.scss';
@import 'assets/styles/tailwind_extension.scss';
@import 'assets/styles/button.scss';
@import 'assets/styles/scrollbar.scss';
@import 'assets/styles//menu.scss';
@import 'assets/styles//search-input.scss';
@import 'assets/styles/autofill.scss';
@import 'assets/styles/blur.scss';
@import 'assets/styles/input.scss';

.radial-corner-bottom {
    width: 48rem;
    height: 48rem;
    background: radial-gradient(circle at bottom left,
            rgba(64, 108, 204, 0.6) 0%,
            rgba(64, 108, 204, 0.3) 20%,
            rgb(3, 6, 25, 1) 50%);
}

.radial-full {
    width: 96rem;
    height: 96rem;
    top: -50rem;
    left: -50rem;
    background: radial-gradient(circle,
            rgba(64, 108, 204, 0.6) 0%,
            rgba(64, 108, 204, 0.3) 20%,
            rgb(3, 6, 25, 1) 50%);
    position: relative;
    animation: moveRandom 10s infinite ease-in-out alternate;
}

/* Tablet (640px - 767px) */
@media (min-width: 640px) {
    .radial-full {
        top: auto;
        width: 96rem;
        height: 96rem;
        bottom: -50rem;
        left: -50rem;
    }
}

/* Animación para mover de izquierda a derecha con variación */
@keyframes moveRandom {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(30rem + 5rem * (1 - 2 * random())));
    }
}

.line-gradient-horizontal {
    height: 1.5px;
    background: linear-gradient(to right,
            rgb(255, 255, 255, 0.05) 0%,
            rgb(255, 255, 255, 0) 100%,
        );
}

.line-gradient-vertical {
    width: 1.5px;
    background: linear-gradient(to top,
            rgb(255, 255, 255, 0.05) 0%,
            rgb(255, 255, 255, 0) 100%);
}

app-learn,
app-practice,
app-chat,
app-dictionary,
app-custom-conversation,
app-picture-description,
app-subscription {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    max-width: 100%;
    min-width: 0;

    /* Base styles for individual layouts */
    >* {
        position: relative;
        display: flex;
        flex: 1 1 auto;
        width: 100%;
    }

    /* Base styles for components that load as a route */
    router-outlet {

        +* {
            position: relative;
            display: flex;
            flex: 1 1 auto;
            width: 100%;
        }
    }
}


// Allow tailwind color for SVG icons
[data-mat-icon-type="svg"] {
    svg {
        fill: currentColor !important;
    }
}
